.footer-main-container{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: #194373
}
.footer-sub-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:20px 0px;
}
.footer-flex{
    display:flex;
    gap:20px;
}
.footer-social-icon{
    width:30px;
    height:30px;
    border-radius: 50%;
    background-color:#fff;
    padding:8px;
    cursor:pointer;
}