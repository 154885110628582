.masid-form-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.masid-regiser-form {
  background-color: #194373;
  color: white;
  margin-top: 1px;
  padding: 20px;
}

.masid-form-container {
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.masid-form-field {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.masid-form-field-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  justify-self: center;
}

.masid-form-lable {
  margin-bottom: 5px;
}

.masid-form-input,
.masid-form-input,
textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.file-input-container {
  display: flex;
  align-items: center;
}
::file-selector-button {
  background-color: #194373;
  font-size: 14px;
  color: white;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 5px;
}
.masid-form-button {
  background-color: #60c2d3;
  font-size: 16px;
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 200px;
}

@media (max-width: 500px) {
  .masjid-form-time-container {
    width: 50% !important;
  }
}
.masjid-form-time-container {
  width: 20%;
}

.masid-form-field-checkbox-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  gap: 10px;
  justify-self: center;
}
.masjid-register-button-container {
  justify-self: center;
  display: flex;
  justify-content: center;
}
