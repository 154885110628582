.admin-navbar-main-container{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #194373;
    height:80px;
}
.admin-navbar-sub-container{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:90%;
}
.admin-nav-item-name{
    margin:0px;
    font-size:16px;
    color:#fff;
}