.home-image-bg-container{
    background-image: url('../../utils/merimasjidbg-4.png');
    /* background-image: url("https://res.cloudinary.com/dwsrrlpjl/image/upload/v1715601295/hhse4fh5lhqf838opbql.png"); */
    background-repeat: no-repeat;
    background-size:100% 100%;
    height:100vh;
    
    display:flex;
    align-items: center;
    
    width:100%;
}
.home-image-sub-container{
    display:flex;
    flex-direction: column;
    gap:6px;
    margin-left:100px;
}
.home-page-button-container{
    display:flex;
    flex-wrap: wrap;
    gap:20px;
}
@media (min-width:0px) and (max-width:768px){
    .home-image-sub-container{
        align-items: center;
        margin:0px;
    }
    .home-page-button-container{
        flex-direction: column;
        align-items: center;
    }
    .home-image-bg-container{
        justify-content: center;
    }
}