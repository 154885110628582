.near-by-masjeed-main-container {
  width: 40%;
  overflow: scroll;
  height: 100vh;
  min-width: 200px;
}
.near-by-masjeed-cards-container {
  display: flex;
  flex-direction: column;
  background-color: #e4edf1f0;
  gap: 10px;
}
.near-by-masjeed-main-container-title {
  font-size: 25px;
  font-weight: bold;
  color: #1a5287;
  padding: 10px 25px;
  margin-top: 5px;
}

.masjid-card {
  padding: 8px;
  background-color: #fff;
  padding-left: 25px;
}

.masjid-details {
  color: #000;
}

.masjid-actions {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.directions-button,
.timings-button {
  background-color: #fff;
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #1a5287;
  border: 1px solid #959393;
  align-items: center;
  width: 120px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  margin: 0px 7px;
}

.directions-button:hover,
.timings-button:hover {
  background-color: #959393;
  color: #fff;
}

.masjid-details-title {
  font-size: 16px;
  font-weight: 600;
}

.masjid-details-address {
  font-size: 15px;
  font-weight: 400;
  margin: 5px;
  color: #757575;
}
.masjid-details-distance {
  font-size: 15px;
  color: #000;
  margin: 0px;
}

@media (max-width: 650px) {
  .near-by-masjeed-main-container {
    width: 100%;
  }
}
