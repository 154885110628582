.home-banner-main-container {
  display: flex;
}
/* index.css */

.mosque-details-container {
  position: relative;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.mosque-details-container h2 {
  margin-top: 0;
}

.mosque-details-container p {
  margin: 10px 0;
}

.mosque-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.mosque-photos img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.mosque-details-container button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #53CB45;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mosque-details-container button:hover {
  background-color: #45a636;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 650px) {
  .home-banner-main-container {
    flex-direction: column;
  }
}
