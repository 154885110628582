.navbar-sub-container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar-main-container {
  height: 80px;
  background-color: #fff;
  position: relative;
  padding: 0px 25px;
  border-bottom: 8px solid #e4edf1f0;
}

.navbar-company-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-company-logo {
  width: 80px;
  height: 80px;
  padding-top: 10px;
  text-decoration: none;
}
.navbar-menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  text-decoration: none;
}
.nav-elements ul a:hover {
  color: #60c2d3;
  font-weight: 500;
  position: relative;
}
.nav-elements ul a.nav-active {
  color: #60c2d3;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.nav-active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .navbar-menu-icon {
    display: block;
    cursor: pointer;
    color: #fff;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 80px;
    background-color: #3b4044;
    width: 0px;
    height: max-content;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.nav-active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

.navbar-company-logo-container-title {
  font-size: 20px;
  color: #1a5287;
  font-weight: bold;
  padding-top: 10px;
  text-decoration: none;
}

.navbar-search-button {
  background-color: #fff;
  display: flex;
  gap: 10px;
  color: #3b4044;
  border: 1px solid #3b4044;
  align-items: center;
  width: 250px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  margin: 0px 7px;
  text-decoration: none;
  margin-left: 120px;
  text-decoration: none;
}
.no-decoration {
  text-decoration: none;
}
