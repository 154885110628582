.features-main-container{
    
    width:100%;
    display:flex;
    justify-content: center;
    margin:30px 0px;
}
.features-sub-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f7f7f7;
    width:100%;
    padding:40px 0%;
}
.features-card-container{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    gap:20px;
}
.features-card{
    background-color: #fff;
    display:flex;
    gap:10px;
    padding:20px;
    border-radius:10px;
    width:24%;
}
.features-icon-container{
    width:40px;
    height:40px;
    border-radius: 50%;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.features-icon{
    color:#60c2d3;
    font-size:24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:40px;
    height:40px;
}
@media(max-width:512px){
    .features-card-container{
        flex-direction: column;
        align-items: center;
        
    }
    .features-card{
        width:80%;
    }
    .features-sub-container{
        width:90%;
    }
}