.select-masjid-warning-container{
     min-height:100vh;
    width:100%;
    background-color: #d2d2d2b5;
}
.select-masjid-time-flex-container{
    display:flex;
    align-items: center;
    justify-content:center;
    min-height:100vh;
    width:100%;
    flex-wrap:wrap;
    gap:30px;
}
.single-masjid-list-main-container{
  width:55%;
}
.single-masjid-header{
    width:100%;
    background-color:#f2f2f2;
    display:flex;
    align-items: center;
    justify-content: space-between;
    height:36px;
}
.single-masjid-header-name{
    width:30%;
    margin:0px;
    font-size:16px;
    color:#39454d;
    text-align: center;
    font-weight:600;
}
.single-masjid-content{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
    height:36px;
}
.single-masjid-content-name{
    width:30%;
    margin:0px;
    font-size:16px;
    color:#808080;
    text-align: center;
}
.warningScroll{
  animation: marquee 10s linear infinite;
  display:flex;
  align-items: center;
  gap:20px;
  padding-top:60px;
 
}
.warning-scroll-image-container{
    width:150px;
    height:150px;
}
.warning-scroll-image{
    width:100%;
    height:100%;
    object-fit: contain;
}
.warning-text{
     color:#ef233c;
  margin:0px;
  font-size:48px;
  font-weight:600;
  
}

.ramzan-timings-container{
  display:flex;
  flex-direction: column;
  gap:20px;
  width:30%;
  
}

.ramzan-sehar-time{
  /* color:  #00c87a;  */
  color:#194373;
  font-size: 36px;
  font-weight: bold;
}
.ramzan-iftar-time{
  /* color:  #9d64e2;  */
  font-size: 36px;
  font-weight: bold;
  color:#194373;
}
.message-icon-box{
  font-size:24px;
  cursor:pointer;
}
@media(max-width:768px){
  .select-masjid-time-flex-container{
    flex-direction: column-reverse;
  }
  .single-masjid-list-main-container{
    width:90%;
  }
  .ramzan-timings-container{
    width:90%;
    align-items: center;
  }
}

@keyframes marquee {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}