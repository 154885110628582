.trusted-by-main-container{
    padding:0% 3%;
    margin-top:40px;
}
.trusted-by-card-container{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    gap:20px;
}
.trusted-by-card{
    width:30%;
    height:200px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #f7f7f7;
    border-radius:10px;
    color:#fff;
}
@media(max-width:512px){
    .trusted-by-card-container{
        flex-direction: column;
        align-items: center;
    }
    .trusted-by-card{
        width: 90%;
    }
}