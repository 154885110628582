.privacy-policy-main-container{
    width:100%;
}
.privacy-policy-heading-container{
     background-color: #194373;
    color: white;
     padding: 35px;
     margin-top:1px;
}
.privacy-text{
    font-size: 18px;
    margin: 0px;
    color: #808080
}
.privacy-policy-text-container{
    display:flex;
    flex-direction: column;
    gap:10px;
    padding:10px 30px;
}
.privacy-list{
    font-size: 14px;
    margin: 0px;
    color: #808080
}