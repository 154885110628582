.masjid-register-popup-main-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80%;
}

.masjid-popup-tick-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: #757575;
}
.masjid-popup-response {
  font-size: 16px;
  font-weight: 800;
}
.masjid-popup-contact {
  font-size: 16px;
  font-weight: 400;
}
.masjid-popup-done-button {
  display: block;
  width: 100%;
  background-color: #60c2d3;
  color: #fff;
  border: none;
  border-radius: 10px;
}
